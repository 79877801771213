* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.container {
  /*display: flex;
  justify-content: space-between;
  width: 99vw;
  height: 85vh;
  margin-top: 3vh;*/
  display: flex;
  flex-wrap: wrap;            /* Allow wrapping to the next line */
  width: 100%; /* Make sure the container uses full width */
}

.box {
  flex: 1;
  display: flex;
  flex-direction: column; /* Alignement vertical à l'intérieur de chaque box */
  align-items: flex-start; /* Alignement des enfants en haut (gauche par défaut) */
  justify-content: flex-start; /* Alignement des enfants en haut */
  height: 85vh;
  margin: 0 5px;
}

.mini_container {
  display: grid;
  grid-template-columns: 1fr 1fr 0.25fr 0.5fr 0.25fr; /* 1 1 0.5 */
  gap: 10px; /* Espace entre les éléments */
}

.mini_box {
  display: flex;
  justify-content: center;
  align-items: center;       /* Centre verticalement */
  padding: 8px;
  margin: 8px;
}

.full-width-box {
  display: flex;
  flex: none;
  flex-direction: column; /* Alignement vertical à l'intérieur de chaque box */
  width: 100%; /* Utilise 100% de la largeur de la fenêtre */
  padding: 10px 25px 10px 75px; /* Ajoute du padding si nécessaire */
  margin-top: 10px; /* Marge pour espacer de la section précédente */
  border-top: 2px solid var(--BOULE_04_COLOR); /* Only top border for visibility */
}

.box img {
  max-width: 100%;  /* Limite la largeur à celle de la boîte */
  max-height: 100%; /* Limite la hauteur à celle de la boîte */
  object-fit: contain; /* Ajuste l'image pour qu'elle remplisse la box sans déformer */
  width: auto;      /* S'adapte proportionnellement à la boîte */
  height: auto;     /* S'assure que le ratio de l'image est conservé */
  margin: 0 auto;    /* Centre l'image horizontalement */
  animation: grow 2s ease forwards; /* Animation to grow */
}

.box div img
{
  width: 128px;
  border-radius: 20px;
  border: 0px;
}

.customImgSize1
{
  width: 128px !important;

}

.customImgSize2
{
  width: 192px !important;

}
.customBackground
{
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
}

p
{
  font-size: 2.2em;
  color: black;
}

h1
{
  font-size: 2.8em;
  color: #393b3d;
}

.box > div 
{
  animation: grow 2s ease forwards; /* Animation to grow */
}

/* BottomNavbar.css */
.bottom-navbar {
  position: fixed; /* Fixed position */
  bottom: 0; /* Align to the bottom of the viewport */
  left: 0; /* Align to the left */
  /*width: 200px; /* Set a fixed width for the navbar */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the start (left) */
  /*background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  color: white; /* Text color */
  /*padding: 10px; /* Padding for aesthetics */
  /*box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5); /* Shadow for depth */
  z-index: 1000; /* Ensure it's on top of other content */
}

.nav-item {
  cursor: pointer; /* Change cursor to pointer */
  width: 100%; /* Make items take full width of the navbar */
}

.nav-item:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Highlight on hover */
  border-radius: 5px; /* Rounded corners */
}

/*.box:nth-child(1) {
  background-color: #ff9999;
}

.box:nth-child(2) {
  background-color: #99ccff;
}

.box:nth-child(3) {
  background-color: #99ff99;
}*/
.nav-icon {
  width: 64px !important;
  height: 64px !important;
  color: var(--LIGTH_DARK) !important;
  /*border-radius: 50px;
  border: 2px solid;
  padding: 3px;
  /*margin-right: 15px; /* Adjust margin as needed */
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-1dr3gdi-MuiPaper-root {
  box-shadow: none !important;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper /* DEBUG */
{
  background: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(2px) !important;
}
.css-pwxzbm  /* PRODUCTION */
{
  background: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(2px) !important;
}

@keyframes wobble {
  0% {
    transform: translate(0);
  }
  15% {
    transform: translate(-5px, 0);
  }
  30% {
    transform: translate(5px, 0);
  }
  45% {
    transform: translate(-5px, 0);
  }
  60% {
    transform: translate(5px, 0);
  }
  75% {
    transform: translate(-5px, 0);
  }
  100% {
    transform: translate(0);
  }
}

.wobble-on-hover {
  display: inline-block; /* Ensures the element takes the right space */
  transition: transform 0.2s; /* Smooth transition */
}

.wobble-on-hover:hover {
  animation: wobble 0.5s ease-in-out; /* Trigger the wobble animation on hover */
}

.ico_size
{
  width: 72px !important;
  height: 72px !important;
  margin: 10px !important;
}

.custom_width_1
{
  width: 450px;
}

.custom_width_2
{
  width: 350px;
}

.customMarginRigth
{
  margin-right: 256px;
}

.CustomMaxSize
{
  max-width: 1000px;
  max-height: 700px;
}

.css-tmmfpb
{
  box-shadow: none !important;
}

/* Media Queries for mobile screens */
@media (max-width: 1024px) {

  .mini_box_custom {
    padding: 0px;
    margin: 0px;
  }
  .CustomMaxSize
  {
    max-width: 400px;
    max-height: 400px;
  }

  .customMarginRigth
  {
    margin-right: 8px;
  }
  .custom_width_1
  {
    width: auto;
  }
  
  .custom_width_2
  {
    width: auto;
  }
  .ico_size
  {
    width: 48px !important;
    height: 48px !important;
    margin: 0.5px !important;
  }

  .customImgSize1
  {
    width: 92px !important;
  }

  .customImgSize2
  {
    width: 128px !important;
  }
  .mini_container {
    /*grid-template-columns: repeat(1, 1fr); /* 4 colonnes égales */
    grid-template-columns: 1fr; /* 1 1 0.5 */
  }
  .nav-icon {
      width: 32px !important; /* Reduce icon size for mobile */
      height: 32px !important; /* Reduce icon height for mobile */
      /*margin-right: 10px; /* Adjust margin for mobile */
      /*padding: 2px; /* Adjust padding for mobile */
  }
  .container {
    flex-direction: column; /* Change l'orientation en colonne sur mobile */
    height: 85vh; /* Permet aux div d'occuper la taille naturelle en hauteur */
    margin-top: 2vh;
    width: 98vw;
    display: block;
  }

  .box {
    margin: 10px 0; /* Ajoute de l'espacement vertical entre les div */
    height: auto; /* Hauteur fixe des div sur mobile, peut être ajustée */
  }

  .box > div {
    /*width: 90%; /* Ajuste la largeur pour qu'elle prenne moins d'espace */
    margin: 10px auto !important; /* Centre et ajuste la marge verticale */
    /*padding: 10px 20px; /* Réduit le padding pour mobile */
  }
  
  .box div img
  {
    width: 40px;
    border-radius: 20px;
    border: 0px;
  }

  .full-width-box {
      padding: 15px; /* Ajustement du padding pour mobile */
      font-size: 16px; /* Ajustement de la taille de police si nécessaire */
  }

  p
  {
    font-size: 1em;
  }

  h1
  {
    font-size: 1.6em;
    color: var(--TEXT_COLOR) !important;
  }

}

/* Keyframes for growing animation */
@keyframes grow {
  0% {
    transform: scale(0);  /* Start small */
    opacity: 0;           /* Start invisible */
  }
  50% {
    transform: scale(1.1); /* Grow slightly larger */
    opacity: 0.5;          /* Fade in */
  }
  100% {
    transform: scale(1);   /* End at normal size */
    opacity: 1;            /* Fully visible */
  }
}

:root 
{
  --BACKGROUND_COLOR: #e6ede6;
  --BOULE_01_COLOR: #B8CBD0;
  --BOULE_02_COLOR: #B0B9A8;
  --BOULE_03_COLOR: rgb(169, 160, 160);
  --BOULE_04_COLOR: #b0b9a8;
  --TEXT_COLOR: black;
  --LIGTH_DARK: #393b3d;
}

html 
{
  background-color: var(--BACKGROUND_COLOR);
  scroll-behavior: smooth;
}

.MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper MuiMenu-paper MuiMenu-paper css-pwxzbm 
{
  background-color: var(--BOULE_03_COLOR) !important;
}

.vertical-timeline-element-icon 
{
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
  float: left;
  padding: 0 !important; 
  opacity: 1 !important;
}

.vertical-timeline-element-icon svg
{
  width: 24px !important;
  height: 24px !important;
  margin: 0px !important;
  left: 0px !important;
  top: 0px !important;
}

.test::before {
  display: block;
  height: 5px;
  padding-top: 1%;
  visibility: hidden !important;
}

.vertical-timeline-element-content {
  position: relative;
  border-radius: 0.25em;
  box-shadow: none !important;
}

.vertical-timeline::before {
  background: var(--BOULE_04_COLOR) !important;
  animation: Background 100s ease infinite; 
  width: 5px !important;
}

.colorRandom {
  background: var(--BOULE_04_COLOR) !important;
 /*border-color: black;*/
}

*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--BOULE_03_COLOR);
}

*::-webkit-scrollbar-thumb {
  background-color: white;
}

.App {
  text-align: center;
}

#projects {
  scroll-margin-top: 10vh;
}

#contact {
  scroll-margin-top: 10vh;
}

#link0 { scroll-margin-top: 20vh; }
#link1 { scroll-margin-top: 20vh; }
#link2 { scroll-margin-top: 20vh; }
#link3 { scroll-margin-top: 20vh; }
#link4 { scroll-margin-top: 20vh; }
#link5 { scroll-margin-top: 20vh; }
#link6 { scroll-margin-top: 20vh; }
#link7 { scroll-margin-top: 20vh; }
#link8 { scroll-margin-top: 20vh; }
#link9 { scroll-margin-top: 20vh; }
#link10 { scroll-margin-top: 20vh; }
#link11 { scroll-margin-top: 20vh; }
#link12 { scroll-margin-top: 20vh; }
#link13 { scroll-margin-top: 20vh; }
#link14 { scroll-margin-top: 20vh; }
#link15 { scroll-margin-top: 20vh; }
#link16 { scroll-margin-top: 20vh; }
#link17 { scroll-margin-top: 20vh; }
#link18 { scroll-margin-top: 20vh; }
#link19 { scroll-margin-top: 20vh; }
#link20 { scroll-margin-top: 20vh; }
#link21 { scroll-margin-top: 20vh; }
#link22 { scroll-margin-top: 20vh; }
#link23 { scroll-margin-top: 20vh; }
#link24 { scroll-margin-top: 20vh; }
#link25 { scroll-margin-top: 20vh; }
#link26 { scroll-margin-top: 20vh; }
#link27 { scroll-margin-top: 20vh; }
#link28 { scroll-margin-top: 20vh; }
#link29 { scroll-margin-top: 20vh; }
#link30 { scroll-margin-top: 20vh; }
#link31 { scroll-margin-top: 20vh; }
#link32 { scroll-margin-top: 20vh; }
#link33 { scroll-margin-top: 20vh; }
#link34 { scroll-margin-top: 20vh; }
#link35 { scroll-margin-top: 20vh; }
#link36 { scroll-margin-top: 20vh; }
#link37 { scroll-margin-top: 20vh; }
#link38 { scroll-margin-top: 20vh; }
#link39 { scroll-margin-top: 20vh; }
#link40 { scroll-margin-top: 20vh; }
#link41 { scroll-margin-top: 20vh; }
#link42 { scroll-margin-top: 20vh; }
#link43 { scroll-margin-top: 20vh; }
#link44 { scroll-margin-top: 20vh; }
#link45 { scroll-margin-top: 20vh; }
#link46 { scroll-margin-top: 20vh; }
#link47 { scroll-margin-top: 20vh; }
#link48 { scroll-margin-top: 20vh; }
#link49 { scroll-margin-top: 20vh; }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*.css-pwxzbm 
{
  background-color: var(--BOULE_03_COLOR) !important;
}*/

#testImage
{
    margin: 1px;
    padding: 1px;
}

@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: var(--BACKGROUND_COLOR);
  overflow: hidden;
  z-index: -2;
}

.background span {
  width: 2vmin;
  height: 2vmin;
  border-radius: 2vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 50;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: var(--BOULE_01_COLOR);
  top: 53%;
  left: 78%;
  animation-duration: 6s;
  animation-delay: -1s;
  transform-origin: -18vw 5vh;
  box-shadow: 4vmin 0 1.4308376707609842vmin currentColor;
}
.background span:nth-child(1) {
  color: var(--BOULE_01_COLOR);
  top: 79%;
  left: 70%;
  animation-duration: 6s;
  animation-delay: -1s;
  transform-origin: -18vw -24vh;
  box-shadow: 4vmin 0 0.6080536863713635vmin currentColor;
}
.background span:nth-child(2) {
  color: var(--BOULE_02_COLOR);
  top: 32%;
  left: 47%;
  animation-duration: 6s;
  animation-delay: -1s;
  transform-origin: 15vw -11vh;
  box-shadow: 4vmin 0 0.6164162872144343vmin currentColor;
}
.background span:nth-child(3) {
  color: var(--BOULE_03_COLOR);
  top: 63%;
  left: 71%;
  animation-duration: 6s;
  animation-delay: -1s;
  transform-origin: 9vw -21vh;
  box-shadow: 4vmin 0 1.4740720128036122vmin currentColor;
}
.background span:nth-child(4) {
  color: var(--BOULE_03_COLOR);
  top: 79%;
  left: 94%;
  animation-duration: 6s;
  animation-delay: -1s;
  transform-origin: 24vw -19vh;
  box-shadow: 4vmin 0 0.763967711346931vmin currentColor;
}
.background span:nth-child(5) {
  color: var(--BOULE_03_COLOR);
  top: 94%;
  left: 47%;
  animation-duration: 6s;
  animation-delay: -1s;
  transform-origin: 20vw 13vh;
  box-shadow: -4vmin 0 0.6738617948620829vmin currentColor;
}
.background span:nth-child(6) {
  color: var(--BOULE_02_COLOR);
  top: 56%;
  left: 88%;
  animation-duration: 6s;
  animation-delay: -1s;
  transform-origin: 5vw 1vh;
  box-shadow: -4vmin 0 0.5741577185646489vmin currentColor;
}
.background span:nth-child(7) {
  color: var(--BOULE_04_COLOR);
  top: 31%;
  left: 9%;
  animation-duration: 6s;
  animation-delay: -1s;
  transform-origin: 19vw -14vh;
  box-shadow: -4vmin 0 1.3493123294360212vmin currentColor;
}
.background span:nth-child(8) {
  color: var(--BOULE_04_COLOR);
  top: 25%;
  left: 34%;
  animation-duration: 6s;
  animation-delay: -1s;
  transform-origin: -2vw -4vh;
  box-shadow: -4vmin 0 1.3546827740411556vmin currentColor;
}
.background span:nth-child(9) {
  color: var(--BOULE_01_COLOR);
  top: 70%;
  left: 84%;
  animation-duration: 6s;
  animation-delay: -1s;
  transform-origin: 1vw 9vh;
  box-shadow: -4vmin 0 0.6550668522461096vmin currentColor;
}
.background span:nth-child(10) {
  color: var(--BOULE_04_COLOR);
  top: 100%;
  left: 91%;
  animation-duration: 6s;
  animation-delay: -1s;
  transform-origin: 13vw -5vh;
  box-shadow: -4vmin 0 1.3962629169968497vmin currentColor;
}